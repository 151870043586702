<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valueIndex) in values" :key="valueIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card :title="$t('Table_Head.Refund')">
      <b-row>
        <!-- sorting Status -->
        <b-col md="2" class="my-1">
          <b-form-group
            :label="$t('Table_Head.Status')"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              style="z-index:9999999;"
              v-model="status"
              class="border mr-0"
              size="sm"
              :text="statusName"
              variant="flat-primary"
            >
              <b-dropdown-item @click="changeStatus('All', 'all')">
                {{ $t("Table_Head.All") }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatus(item.name, item.slug)"
                v-for="(item, index) in refundStatus"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            :label="$t('Table_Head.sortable')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class=""
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">
                  {{ $t("Table_Head.ASC") }}
                </option>
                <option :value="true">
                  {{ $t("Table_Head.DESC") }}
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="my-1">
          <b-form-group
            :label="$t('Table_Head.Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Table_Head.Search')"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="SearchData()">
                  {{ $t("Table_Head.Search") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(desc)="data">
              <b-card-text > <p v-html="data.value"></p> </b-card-text>
            </template>
            <template #cell(status)="data">
              <div class="text-center">
                <b-badge variant="primary">
                  {{ data.value }}
                </b-badge>
              </div>
            </template>
            <template #cell(orderStatus)="data">
              <div class="text-center">
                <b-badge variant="warning">
                  {{ data.value }}
                </b-badge>
              </div>
            </template>
            <template #cell(actions)="items">
              <div>
                <!-- Show Order -->
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/orders/refund/show/' + items.item.id }"
                >
                  <feather-icon
                    style="width:18px; height:18px;"
                    icon="EyeIcon"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body
        class="d-flex justify-content-between p-0 pagination-section"
      >
        <!-- page length -->
        <div class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("Table_Head.Per_Page")
          }}</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
 
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,

      sortBy: "created_at",
      status: "all",
      statusName: this.$t("Table_Head.All"),
      refundStatus: [
        {
          name: this.$t("Table_Head.Pending"),
          slug: "pending",
        },
        {
          name: this.$t("Table_Head.Accept"),
          slug: "accept",
        },
        {
          name: this.$t("Table_Head.Canceled"),
          slug: "cancel",
        },
      ],
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // table fields
      fields: [
        {
          key: "id",
          label: this.$t("Table_Head.ID"),
          sort_by: true,
        },
        {
          key: "code",
          label: this.$t("Table_Head.Code"),
        },
        {
          key: "status",
          label: this.$t("Table_Head.Refund_Status"),
        },

        {
          key: "total",
          label: this.$t("Table_Head.Total"),
        },
        {
          key: "service_cost",
          label: this.$t("Table_Head.Service_Cost"),
        },

        {
          key: "created_at",
          label: this.$t("Table_Head.Created_at"),
          sort_by: true,
        },
        {
          key: "updated_at",
          label: this.$t("Table_Head.Updated_at"),
          sort_by: true,
        },
        {
          key: "actions",
          label: this.$t("Table_Head.Actions"),
        },
      ],
      items: [],
      exportItems: [],
    };
  },
  created() {
    // request Orders index
    this.fetchData();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sort_by)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortDesc: function() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    sortBy: function() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    status: function() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
  },
  methods: {
    // fetch data of Orders
    fetchData() {
      var url = null;
      if (this.filter == "") {
        url =
          "refunds-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.status +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "refunds-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.status +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          const data = result.data.data.data;
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
          // if (data.length > 0) {
          //   for (let index in data) {
          //     this.items.push({
          //       id: data[index].id,
          //       code: data[index].code,
          //       refundStatus: data[index].status,

          //       orderCode: data[index].order.code,
          //       orderStatus: data[index].order.orderstatus.name,
          //       created_at: data[index].created_at,
          //       updated_at: data[index].updated_at,
          //     });
          //   }
          // }

          this.items = data;
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    changeStatus(name, slug) {
      this.status = slug;
      this.statusName = name;
    },
    searchData() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      this.fetchData();
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].username,
          this.items[index].code,
          this.items[index].refundStatus,
          this.items[index].totla,
          this.items[index].discount,
          this.items[index].shipping_price,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [
          [
            "ID",
            "User Name",
            "Code",
            "Order Status",
            "Totla",
            "Discount",
            "Shipping Price",
            "CREATED_AT",
            "UPDATED_AT",
          ],
        ],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style>
@media only screen and (max-width: 425px) {
  .pagination-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pagination-section div {
    margin: 10px;
  }
}
</style>
